<template>
  <div class="book-content__header">
    <r-icon
      :name="iconName"
      :size="24"
    />
    <r-title
      type="title-2"
      color-type="subhead"
    >
      {{ activeBook.name }}
    </r-title>
    <r-title
      type="title-2"
      color-type="secondary"
    >
      {{
        `(Количество объектов: ${dataLength || 0} / ${fullDataLength ||
          0})`
      }}
    </r-title>
    <div
      v-if="hasEditor"
      class="book-content__header-controls"
    >
      <export-button
        v-if="dsUrl"
        :uri="dsUrl"
        :name="activeBookName"
        book-export
      />
      <r-button
        v-if="activeBook.source_id === iconsSourceId"
        mini
        icon="add-plus"
        @click="createIcons"
      >
        <r-text>Загрузка новых иконок</r-text>
      </r-button>
      <r-button
        v-else
        icon="add-plus"
        @click="createNewObject"
      >
        <r-text>{{ $t('book-content-header:' + 'tools:add-new') }}</r-text>
      </r-button>
    </div>
  </div>
</template>

<script>
import exportButton from '@/components/export-button'

export default {
  components: {
    exportButton
  },
  computed: {
    activeBookName() {
      return this.$store.state.book.activeBook?.name || ''
    },
    activeFields() {
      return this.$store.state.book.activeFields?.fields || []
    },
    dsUrl() {
      return this.$store.state.book.dsUrl || ''
    },
    iconsSourceId() {
      return this.$store.state.book.iconsSourceId
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    fullDataLength() {
      return this.activeBook?.fullDataLength || null
    },
    dataLength() {
      return this.activeBook?.dataLength || null
    },
    iconName() {
      const geomType = this.getGeomType()

      switch (geomType) {
        case 'LineString':
        case 'MultiLineString':
          return 'line'
        case 'Polygon':
        case 'MultiPolygon':
          return 'polygon'
        case 'Point':
        case 'MultiPoint':
          return 'node'
        default:
          return 'folder-layer'
      }
    },
    hasEditor() {
      return this.$store.getters.hasInstrument('Book', 'Editor')
    }
  },
  methods: {
    createNewObject() {
      this.$store.commit('BOOK_TOGGLE_MODAL', true)
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalName',
        value: 'book-create'
      })
    },
    createIcons() {
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'showIconsModal',
        value: true
      })
    },
    getGeomType() {
      const dataArray = Object.keys(this.activeBook.data)

      if (!dataArray || !dataArray.length) return

      return this.activeBook.data[dataArray[0]]?.geom?.type
    },
    openExport() {
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'showExport',
        value: true
      })
    }
  }
}
</script>

<style lang="scss">
.book-content__header {
  padding: 8px 16px;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast) !important;

  .r-icon {
    width: auto;
  }

  div {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
</style>
